.modal-container {
  height: 100%;
}

.modal-image {
  height: 100%;
}
.uploaded-image {
  border-radius: 3%;
  overflow: hidden;
}

.anchor-list {
  // max height of the list to be no more then 50% of the screen
  max-height: 25vh;
  overflow: auto;
}
