.title-wrapper {
  display: flex;
  align-content: flex-start;
  justify-content: center;

  .logo {
    fill: var(--ion-color-dark);
    width: 100%;
    max-width: 100%;
    min-height: 50px;
  }

  .title {
    text-transform: none !important;
    flex: 0 0;
    padding-inline: 0;
    // make extra large
    font-size: 2.4rem;
  }
}

ion-toolbar {
  --padding-end: 15px;
}
