.tile {
  --padding-end: 0px;
  --padding-start: 0px;

  --inner-padding-end: 0px;
  --inner-padding-start: 0px;

  // no padding or margin bottom
  padding-bottom: 0px;
  margin-bottom: 0px !important;
}

.edit-button {
  --background: none;
  --box-shadow: none;
  opacity: 0.75;
}

.favorite-container {
  width: 100%;

  // no padding or margin top
  padding-top: 0px;
  margin-top: 0px;

  background-color: #fffbef;
  border-radius: 4px;
  // no top rounding
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border: 2px solid #000000;
  // no bottom border
  border-top: none;

  // flex layout horizontal
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  // padding left and right 10px
  padding: 5px 10px;

  // center contents vertically
  align-items: center;
}

.tile-image {
  width: 100%;

  border-radius: 4px;
  border: 2px solid #000000;
  // no bottom border
  border-bottom: none;
  // no bottom rounding
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  // no padding or margin bottom
  padding-bottom: 0px;
  margin-bottom: 0px;
}
