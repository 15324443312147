.title-wrapper {
  display: flex;
  align-content: flex-start;
  justify-content: center;

  .title {
    text-transform: capitalize;
    flex: 0 0;
    padding-inline: 0;
  }
}

.logo {
  fill: var(--ion-color-dark);
  width: 100%;
  max-width: 80%;
  min-height: 50px;
  margin-right: 4px;
}

.profile-image {
  display: flex;
  align-content: flex-end;
  flex-grow: 0;
}
