.modal-container {
  height: 100%;
}

.uploaded-image {
  border-radius: 3%;
  overflow: hidden;
  width: 100%;
}
.upload-button {
  width: 100%;
  flex-shrink: 0;
}
.creator {
  height: 95%;
}

.advanced-details {
  margin-top: 10px;
}

.subtext {
  margin-bottom: 4px;
}

.create-input {
  flex-shrink: 0;
}

.input-wrapper {
  min-height: 50%;
}

.image-container {
  height: 100%;
  width: 100%;
  // make swiper take up all available space
}
.swiper-slide {
  height: 100%;
  overflow: visible;
}
