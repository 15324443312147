.container strong {
  font-size: 20px;
  line-height: 26px;
}

.container p {
  font-size: 16px;
  line-height: 22px;
  color: #8c8c8c;
  margin: 0;
}

.container a {
  text-decoration: none;
}

.container {
  height: calc(100% - 51px);
}

.filter-container {
  padding: 5px 0px;

  margin: auto;

  // remove wrap and put everything on one line
  flex-wrap: nowrap;
  overflow: auto;
}
.divider-sticky {
  --padding-start: 0px;
  // allow horizontal scrolling
  overflow-x: auto;
}

.filter-chip {
  min-width: fit-content;
}
