.user-register {
  // flex column center
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // margin
  width: 100%;

  .user-name {
    // center input text
    text-align: center;
  }
}

.password-icon {
  height: 100%;
}

.default-avatar {
  display: flex;
  margin: auto;
  cursor: pointer;
  margin-bottom: 5px;
  border-radius: 100%;
  border: 2px solid;
  transform: scale(1.4);
}

.primary-error {
  //   Ion text center
  display: flex;
  align-items: center;
  justify-content: center;
}

.forgot-password {
  //   Ion text center
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.password-error {
  height: 48px;
}
