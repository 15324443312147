.modal-container {
  height: 100%;
}
.modal-image {
  height: 100%;
}
.uploaded-image {
  border-radius: 3%;
  overflow: hidden;
}

.anchor-button {
  width: 5%;
  height: 5%;
  background-color: #000;
  opacity: 0.5;
  color: #fff;
  font-size: 1.5rem;
  text-align: center;
  line-height: 3rem;
  text-decoration: none;
}

.advanced-display {
  // flex column
  display: flex;
  flex-direction: column;
}
