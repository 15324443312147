.title-wrapper {
  display: flex;
  align-content: flex-start;
  justify-content: center;

  .title {
    text-transform: capitalize;
    flex: 0 0;
    padding-inline: 0;
  }
}

.logo {
  fill: var(--ion-color-dark);
  width: 100%;
  max-width: 100%;

  height: 90px;
}
