.anchor {
  opacity: 0.65;

  &:hover {
    opacity: 1;
  }
}

.full-opactiy {
  opacity: 1;
}

.anchor-name {
  background-color: #222428;
  color: #fff;
  height: 125px;
  // create a box around the text
  padding: 0.5rem;
  // center the text
  text-align: center;
  // vertical align contents
  display: flex;
  flex-direction: column;
  justify-content: center;

  word-wrap: break-word;
  overflow: hidden;

  // keep inside bounds
  width: 135px;

  // round the corners and make it bound to the image
  border-radius: 0 0.5rem 0.5rem 0.5rem;
  // make the text smaller
  font-size: 0.8rem;

  &-text {
    // make text elipses if it is too long
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 125px;

    // give text slightly higher weight
    font-weight: 600;
  }

  &-body {
    // flex column with space between
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // make the text a bit smaller
    font-size: 0.8rem;

    // make the text elipses if it is too long
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &-footer {
    // flex row with space between
    display: flex;
    justify-content: space-between;
    align-items: center;

    max-width: inherit;
  }
}
